import moment from "moment";
import AppConstants from "../../constants";
import {
  enumerateDaysBetweenDates,
  getUniqueValues,
  getValueFromArray,
  localTimeString,
  transformIntoObj,
  transformIntoObjArrays,
  isUndefined,
  isAString,
  isArrayNonEmpty,
  isNonEmptyObject,
  isUomGrams,
} from "../../utils/helpers.utils";
import { TAB_VALUES_CONSTANT } from "constants/DelveDashboardConstant";
import { QcVerificationStatus, UnitOfMeasure } from "../../constants/CommonConstants";
export interface MetaData {
  transactionDate: Date;
  category: string;
  deliveryType: string;
  modeOfCollection: string;
  invoiceNumber: string;
  internalExternal: string;
  origin: string;
  status: string;
  deliveryStatus: string;
  displayDeliveryStatus: string;
  qcStatusDelivery: QCStatusDelivery;
  lastStatusUpdatedTime: string;
  numberOfItems: any;
  readyToDispatchDate: any;
}
export interface Name {
  ar: string;
  en: string;
}

export interface Address {
  area: string;
  landmark: string;
  building: string;
  apartment: string;
  zoneNumber: string;
  makani: string;
  streetNumber: string;
  streetName: string;
  city: string;
  postalCode: string;
  countryName: string;
  countryIsoCode: string;
  longitude: string;
  latitude: string;
}
export interface ContactInfo {
  name: string;
  phone: string;
  altphone: string;
  email: string;
  address: Address;
}

export interface QCStatusDelivery {
  verifiedBy: string;
  itemsVerified: string;
  issuesFound: string;
  lastVerifiedTime: string;
  verified: boolean;
  verificationStatus: any;
}

export interface PosInformation {
  store: string;
  storeName: string;
  contactInfo: ContactInfo;
}

export interface DeliverySlot {
  startTime: Date;
  endTime: Date;
}

export interface CustomerContactInfo {
  name: string;
  phone: string;
  email: string;
  address: Address;
}

export interface DeliveryInformation {
  deliveryInstructions: string;
  deliveryCost: string;
  carrier: string;
  carrierTrackingId: string;
  shipmentSize: string;
  totalCratesCount: number;
  deliverySlot: DeliverySlot;
  customerContactInfo: CustomerContactInfo;
  driver: Driver;
  helper: Driver;
  vehicle: Vehicle;
  transitTime: string;
  actualTransitTime: string;
  dynamicTransitTime: string;
  estimatedTransitTime: string;
}

export interface PickUpContactInfo {
  companyName: string;
  name: string;
  phone: string;
  email: string;
  address: Address;
}

export interface PickupSlot {
  startTime: Date;
  endTime: Date;
}

export interface PickUpInformation {
  pickUpContactInfo: PickUpContactInfo;
  pickupSlot: PickupSlot;
}

export interface PaymentDetail {
  payBillNo: string;
  paymentPhoneNumber: string;
  uniqueReferenceNumber: string;
  transactionInitiatedTries: any;
  transactionStatus: any;
  timestamp: any;
  collectedAmount: string;
}

export interface Reason {
  deliveryStatus: string;
  reasonCode: string;
  reasonDescription: string;
}

export interface Order {
  id: string;
  orderCode: string;
  consignmentCode: string;
  metadata: MetaData;
  posInformation: PosInformation;
  deliveryInformation: DeliveryInformation;
}

export interface CountryDetails {
  countryCode: string;
  countryName: string;
}

export interface AssignedStatus {
  vehicleAssigned: boolean;
  helperAssigned: boolean;
  driverAssigned: boolean;
  consignmentsAvailable: boolean;
}

export interface Vehicle {
  brand: string;
  country: string;
  crateCapacity: number;
  fleetId: string;
  id: string;
  orderCapacity: number;
  type: string;
  name: string;
}

export interface Driver {
  countryIsoCode: string;
  countryName: string;
  licenseNumber: number;
  id: string;
  name: string;
  type: string;
}

export interface Route {
  id: string;
  routeId: string;
  creationTime: string;
  plannedDate: string;
  createdBy: string;
  carrier: string;
  deliveryProposition: string;
  deliveryArea: string[];
  deliverySlot: string[];
  countryDetails: CountryDetails;
  totalConsignment: number;
  totalCrates: number;
  transitTime: number;
  displayRouteStatus: string;
  hubCode: string;
  hubName: string;
  status: string;
  assignedStatus: AssignedStatus;
  assignedVehicle: Vehicle;
  assignee: Driver;
  helper: Driver;
  actualTransitTime: string;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  requestContinuation: string;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort2 {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface DelveOrdersDataResponse {
  pageNumber: number;
  totalElements: number;
  totalPages: number;
  continuationToken?: string;
  elements: Order[];
}

export interface PlanningRoutesDataResponse {
  pageNumber: number;
  totalElements: number;
  totalPages: number;
  continuationToken?: string;
  elements: Route[];
}
export interface Statuses {
  statusCode: string;
  statusName: string;
  statusDate: string;
  reason: Reason;
}

export interface SubsProducts {
  sku: string;
  name: Name;
  quantity: string;
  shippedQty: string;
  rejectedQty: string;
  unitPrice: string;
  vatPercentage: string;
  uom: string;
  reason: Reason;
  associatedBarcodes?: string[];
  associatedCrates?: AssociatedCrates[];
  effectiveUnitPrice?: string | number;
}
export interface ProductsDataTransform {
  sku: string;
  name: Name;
  entryNumber: string;
  quantity: string;
  shippedQty: any;
  rejectedQty: string;
  unitPrice: string;
  vatPercentage: string;
  uom: string;
  reason: Reason;
  substitution: boolean;
  substitutedProducts: SubsProducts[];
}

export interface QcStatusForMonoOrder {
  reportedQty: string; // if this can also be a number, you could use 'string | number' instead
  verifiedQty: string;
  verified: boolean;
}

interface QcStatus {
  reportedQty: string; // if this can also be a number, you could use 'string | number' instead
  verifiedQty: string;
  verified: QcVerificationStatus;
}

export interface AssociatedCrates {
  crateId: string;
  scanned: boolean;
  quantity: number;
  locationCode: string;
  qcStatus: QcStatus;
}

export interface ProductName {
  ar: string;
  en: string;
}

export interface Product {
  sku: string;
  entryNumber: string;
  hasRejectedProducts: boolean;
  name: ProductName;
  quantity: string;
  shippedQty: string;
  unitPrice: string;
  imgUrl: string;
  vatPercentage: string;
  uom: UnitOfMeasure;
  orderedBarcode: string;
  associatedBarcodes: string[];
  substitution: boolean;
  amendedEntry: boolean;
  substitutedProducts?: SubsProducts[];
  rejectedQty?: string;
  toleranceMinWeight: string;
  toleranceMaxWeight: string;
  associatedCrates?: AssociatedCrates[];
  isAmendedEntry: boolean;
  reason: Reason;
  qcStatusDelivery: QcStatusForMonoOrder;
  qcStatusReturn: QcStatusForMonoOrder;
  qcReportsDelivery: Reason;
  effectiveUnitPrice?: string | number;
}

export interface StandardProducts {
  products: ProductsDataTransform[];
}

export const getStatusWithoutUnderScore = (status: any) => {
  if (!isUndefined(status) && isAString(status)) {
    return status.replaceAll("_", " ");
  }
  return "";
};

export const getStatusWithUnderScore = (status: any) => {
  if (!isUndefined(status) && isAString(status)) {
    return status.replaceAll(" ", "_");
  }
  return "";
};

export const getValueFromMetaData = (metadata: any, tabValue: any, keyName: any) => {
  let qcKeyName = "qcStatusDelivery";
  if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
    qcKeyName = "qcStatusReturn";
  }
  if (metadata !== undefined && metadata[qcKeyName] !== undefined && metadata[qcKeyName][keyName] !== undefined) {
    return metadata[qcKeyName][keyName];
  }
  return "";
};

export const getReportValueFromMetaData = (metadata: any, tabValue: any, keyName: any) => {
  let qcKeyName = "qcReportsDelivery";
  if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
    qcKeyName = "qcReportsReturn";
  }
  if (metadata !== undefined && metadata[qcKeyName] !== undefined && metadata[qcKeyName][keyName] !== undefined) {
    return metadata[qcKeyName][keyName];
  }
  return "";
};

export const getDateValueFromMetaData = (metadata: any, tabValue: any, keyName: any, formatStyle: any) => {
  let qcKeyName = "qcStatusDelivery";
  if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
    qcKeyName = "qcStatusReturn";
  }
  if (metadata !== undefined && metadata[qcKeyName] !== undefined && metadata[qcKeyName][keyName] !== undefined) {
    return moment(metadata[qcKeyName][keyName], AppConstants.DATE_FORMAT_BACKEND).format(formatStyle);
  }
  return "";
};

export const getItemsFromMetaData = (metadata: any, tabValue: any) => {
  let keyName = "numberOfItems";
  if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
    keyName = "returnedNumberOfItems";
  }
  if (metadata !== undefined && metadata[keyName] !== undefined) {
    return metadata[keyName];
  }
  return "0";
};

export const delveOrdersDataTransform = (response: DelveOrdersDataResponse, tabValue: any): any[] => {
  const transformedArray: any[] = [];
  response.elements.forEach((order: Order) => {
    const {
      id,
      posInformation: { store },
      consignmentCode,
      metadata,
      deliveryInformation,
    } = order;
    const consignmentStatus = getValueFromMetaData(metadata, tabValue, "verificationStatus");
    const deliveryDate = moment(deliveryInformation.deliverySlot.startTime, AppConstants.DATE_FORMAT_BACKEND);
    const readyToDispatchDate = metadata && metadata.readyToDispatchDate ? moment(metadata.readyToDispatchDate, AppConstants.DATE_FORMAT_BACKEND) : "";
    const startTime = new Date(deliveryInformation.deliverySlot.startTime);
    const endTime = new Date(deliveryInformation.deliverySlot.endTime);
    const totalItems = getItemsFromMetaData(metadata, tabValue);
    const verifiedBy = getValueFromMetaData(metadata, tabValue, "verifiedBy");
    const verificationDate = getDateValueFromMetaData(metadata, tabValue, "lastVerifiedTime", AppConstants.DATE_FORMAT);
    const time = getDateValueFromMetaData(metadata, tabValue, "lastVerifiedTime", AppConstants.SLOT_FORMAT_UI);
    const itemsVerified = getValueFromMetaData(metadata, tabValue, "itemsVerified");
    const issuesFound = getValueFromMetaData(metadata, tabValue, "issuesFound");
    const verificationStatus = getValueFromMetaData(metadata, tabValue, "verified");
    const verifiedItems = Number(itemsVerified); /** - Number(issuesFound); https://mafretail.atlassian.net/browse/DLV-2617 **/
    transformedArray.push({
      id: id,
      hubName: store, // Hub Name
      consignmentId: consignmentCode, // Consignment Number
      deliveryDate: deliveryDate.format(AppConstants.DATE_FORMAT), // Delivery Date
      deliverySlot: `${localTimeString(startTime)} - ${localTimeString(endTime)}`, // Delivery Slot
      crates: deliveryInformation.totalCratesCount, // Crates
      totalItems: totalItems, // Total Items
      issuesFound: issuesFound || 0, // Issues Found
      itemsVerified: verifiedItems || 0, // Items Verified
      verificationDate: verificationDate, // Verification Date
      verificationTime: time, // Verification Time
      verifiedBy: verifiedBy, // Verified By
      driverName: deliveryInformation.driver ? deliveryInformation.driver.name : "", // Driver name
      deliveryStatus: metadata.deliveryStatus,
      issues: Number(getValueFromMetaData(metadata, tabValue, "issuesFound")),
      readyToDispatchDate: readyToDispatchDate !== "" ? readyToDispatchDate.format(AppConstants.DATE_FORMAT) : "-", // Ready To Dispatch Date
      verified: getStatusWithoutUnderScore(consignmentStatus), // Consignment Status
      verificationStatus: getStatusWithoutUnderScore(verificationStatus), // Verification Status verified
    });
  });
  return [...transformedArray];
};

export const getConsignmentStatusField = (fieldName: string, statusCode: string) => {
  let statusCodeCopy = "" as any;
  if (!isUndefined(statusCode) && isAString(statusCode)) {
    statusCodeCopy = getStatusWithUnderScore(statusCode);
    const statuses: any = AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
    return statusCodeCopy && fieldName && statuses && statuses[statusCodeCopy] && statuses[statusCodeCopy][fieldName] ? statuses[statusCodeCopy][fieldName] : "";
  }
  return "";
};

export const getVerificationStatusField = (fieldName: string, statusCode: string) => {
  let statusCodeCopy = "" as any;
  if (!isUndefined(statusCode) && isAString(statusCode)) {
    statusCodeCopy = getStatusWithUnderScore(statusCode);
    const statuses: any = AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
    const newStatus = statusCodeCopy.toUpperCase();
    return statusCode && newStatus && fieldName && statuses[newStatus] && statuses[newStatus][fieldName] ? statuses[newStatus][fieldName] : "";
  }
  return "";
};

export const rejectionDataTransform = (response: any) => {
  const transformedArray: any[] = [];
  response &&
    response.forEach((product: ProductsDataTransform) => {
      const { sku, entryNumber, rejectedQty, name, unitPrice } = product;
      let showReason = false;
      let qty = product.quantity;
      let isSubstitution = product.hasOwnProperty("substitutedProducts");
      let isScalable = product.uom?.toLowerCase() === "grams";
      let totalQty = isScalable ? 1 : isSubstitution ? product.substitutedProducts && product.substitutedProducts.length > 0 && product.substitutedProducts[0].quantity : +qty;
      const rejectedItems: any = isSubstitution ? (+product.substitutedProducts && product.substitutedProducts.length && product.substitutedProducts[0].rejectedQty) || 0 : +product.rejectedQty || 0;
      const initialQty = isScalable ? (rejectedItems > 0 ? 1 : 0) : rejectedItems;
      let shipedQty = isScalable ? 1 : product.substitutedProducts && product.substitutedProducts.length ? product.substitutedProducts[0].shippedQty : product.shippedQty ? product.shippedQty : 0;

      let newObj: any = [];
      let selectRejected = [];
      for (let i = 0; i <= shipedQty; i++) {
        newObj = transformIntoObjArrays(i, shipedQty);
      }
      if (initialQty === 0) {
        selectRejected = transformIntoObj(initialQty);
      } else {
        selectRejected = transformIntoObj(initialQty);
      }
      let deliveryReason = [];
      if (product.reason) {
        deliveryReason.push({
          name: product.reason.reasonDescription,
          value: product.reason.reasonCode,
        });
      } else {
        if (product.substitutedProducts) {
          product.substitutedProducts.forEach((subProd: any) => {
            if (subProd.reason) {
              deliveryReason.push({
                name: subProd.reason.reasonDescription,
                value: subProd.reason.reasonCode,
              });
            }
          });
        }
      }
      //Logic to showReason only when the rejectedQty is not zero
      if (rejectedQty !== "0" && !isUndefined(rejectedQty)) {
        showReason = true;
      }
      transformedArray.push({
        id: sku,
        name: name,
        entryNumber: entryNumber,
        shippedQty: shipedQty || 0,
        rejectedQty: rejectedQty || 0,
        unitPrice: unitPrice,
        totalQty: totalQty,
        rejectedQtyList: newObj,
        selectedRejectedQty: selectRejected,
        itemDeliveryReason: deliveryReason,
        substitutedProducts: product.substitutedProducts,
        showReason: showReason,
        error: "",
      });
    });
  return [...transformedArray];
};

export const sortedCountryData = (columnName: string, isDescending: boolean) => (a: any, b: any) => {
  const aVal = isDescending ? a : b;
  const bVal = isDescending ? b : a;
  if (aVal[columnName].match(/^[0-9]+$/) && bVal[columnName].match(/^[0-9]+$/)) {
    return parseFloat(aVal[columnName]) - parseFloat(bVal[columnName]);
  } else {
    if (aVal[columnName] < bVal[columnName]) {
      return -1;
    } else if (aVal[columnName] > bVal[columnName]) {
      return 1;
    } else {
      return 0;
    }
  }
};

const getFilterAttributeValue = (attrValue: any, noKey?: boolean) => {
  let currentValue = noKey ? attrValue : attrValue.map((item: any) => item.value);
  const selectAllIdx = currentValue.indexOf(AppConstants.SELECT_ALL.value);
  const selectNoneIdx = currentValue.indexOf(AppConstants.SELECT_NONE.value);
  if (selectAllIdx > -1) {
    currentValue = [];
  }
  if (selectNoneIdx > -1) {
    currentValue = [];
  }
  return currentValue;
};

export const createChipsCountFilterPayload = (payload: any, data?: any, isLoadMore?: boolean, selectedChip?: any, isChipClicked?: boolean) => {
  payload.attributes = [];
  if (payload.hub) {
    if (payload.hub.value) {
      let hubValue = payload.hub.value.map((item: any) => item.value);
      let hubNameValue = [];
      if (payload.hubName && payload.hubName.value.length) {
        hubNameValue = payload.hubName.value.map((item: any) => item.value);
      }
      hubValue = hubValue.concat(hubNameValue);
      const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        hubValue = [];
      }
      if (selectNoneIdx > -1) {
        hubValue = [];
      }
      payload.attributes.push({
        key: payload.hub.fieldName,
        value: getUniqueValues(hubValue),
      });
      delete payload.hub;
      delete payload.hubName;
    } else {
      payload.attributes.push({
        key: payload.hub.fieldName,
        value: [],
      });
      delete payload.hub;
      delete payload.hubName;
    }
  }
  if (payload.deliveryArea) {
    let attrValue = [];
    if (payload.deliveryArea.value) {
      attrValue = getFilterAttributeValue(payload.deliveryArea.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryArea.fieldName,
      value: attrValue,
    });
    delete payload.deliveryArea;
  }
  if (payload.orderType) {
    let attrValue = [];
    if (payload.orderType.value) {
      attrValue = getFilterAttributeValue(payload.orderType.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.orderType.fieldName,
      value: attrValue,
    });
    delete payload.orderType;
  }
  if (payload.verified) {
    payload.attributes.push({
      key: payload.verified.fieldName,
      value: [],
    });
    delete payload.verified;
  }
  // if (payload.deliveryStatus) {
  //   let attrValue = [];
  //   if (payload.deliveryStatus.value) {
  //     if (payload.deliveryStatus.value.length) {
  //       attrValue = getFilterAttributeValue(payload.deliveryStatus.value);
  //     } else {
  //       attrValue = ["READY_TO_DISPATCH", "PACKING_COMPLETE", "PREPARED", "PREPARING", "ON_HOLD", "PENDING_DELIVERY", "CANCELLED", "DELIVERED", "DELIVERY_FAILED", "PARTIALLY_DELIVERED"];
  //     }
  //   } else {
  //     attrValue = [];
  //   }
  //   payload.attributes.push({
  //     key: payload.deliveryStatus.fieldName,
  //     value: attrValue,
  //   });
  //   delete payload.deliveryStatus;
  // }
  if (payload.paymentType) {
    let attrValue = [];
    if (payload.paymentType.value) {
      attrValue = getFilterAttributeValue(payload.paymentType.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.paymentType.fieldName,
      value: attrValue,
    });
    delete payload.paymentType;
  }
  if (payload.carrier) {
    let attrValue = [];
    if (payload.carrier.value) {
      attrValue = getFilterAttributeValue(payload.carrier.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.carrier.fieldName,
      value: attrValue,
    });
    delete payload.carrier;
  }
  if (payload.deliveryProposition) {
    payload.attributes.push({
      key: payload.deliveryProposition.fieldName,
      value: ["STD"],
    });
    delete payload.deliveryProposition;
  }
  if (payload.routeId) {
    let attrValue = [];
    if (payload.routeId.value) {
      attrValue = getFilterAttributeValue(payload.routeId.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.routeId.fieldName,
      value: attrValue,
    });
    delete payload.routeId;
  }
  if (payload.routeStatus) {
    let attrValue = [];
    if (payload.routeStatus.value) {
      attrValue = getFilterAttributeValue(payload.routeStatus.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.routeStatus.fieldName,
      value: attrValue,
    });
    delete payload.routeStatus;
  }
  if (payload.driver) {
    let attrValue = [];
    if (payload.driver.value) {
      attrValue = getFilterAttributeValue(payload.driver.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.driver.fieldName,
      value: attrValue,
    });
    delete payload.driver;
  }
  if (payload.vehicle) {
    let attrValue = [];
    if (payload.vehicle.value) {
      attrValue = getFilterAttributeValue(payload.vehicle.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.vehicle.fieldName,
      value: attrValue,
    });
    delete payload.vehicle;
  }
  if (payload.helper) {
    let attrValue = [];
    if (payload.helper.value) {
      attrValue = getFilterAttributeValue(payload.helper.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.helper.fieldName,
      value: attrValue,
    });
    delete payload.helper;
  }
  if (payload.deliveryFailedReason) {
    let attrValue = [];
    if (payload.deliveryFailedReason.value) {
      attrValue = getFilterAttributeValue(payload.deliveryFailedReason.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryFailedReason.fieldName,
      value: attrValue,
    });
    delete payload.deliveryFailedReason;
  }
  if (payload.createdBy) {
    let attrValue = [];
    if (payload.createdBy.value) {
      attrValue = getFilterAttributeValue(payload.createdBy.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.createdBy.fieldName,
      value: attrValue,
    });
    delete payload.createdBy;
  }
  if (payload.deliverySlot && payload.deliverySlot.value) {
    let delSlotValue = payload.deliverySlot.value.map((item: any) => item.value);
    let dateRange = [];
    const selectAllIdx = delSlotValue.indexOf(AppConstants.SELECT_ALL.value);
    if (payload.dateRange && payload.dateRange.value) {
      const allDates = enumerateDaysBetweenDates(payload.dateRange.value.startDate, payload.dateRange.value.endDate);
      if (selectAllIdx > -1 || !delSlotValue.length) {
        payload.isDateRange = true;
        dateRange.push({
          startTime: moment(payload.dateRange.value.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
          endTime: moment(payload.dateRange.value.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND),
        });
      } else {
        payload.isDateRange = false;
        delSlotValue.forEach((slot: any) => {
          const splitSlot = slot.split("-");
          const startSlot = splitSlot[0].split(":");
          const endSlot = splitSlot[1].split(":");
          if (allDates.length) {
            allDates.forEach((date: string) => {
              dateRange.push({
                startTime: moment(date, AppConstants.DATE_FORMAT).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                endTime: moment(date, AppConstants.DATE_FORMAT).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
              });
            });
          }
        });
      }
      if (payload.reset) {
        delete payload.isDateRange;
      }
    }
    payload.dateRange = dateRange;
    delete payload.displayRouteStatus;
    delete payload.deliverySlot;
    delete payload.reset;
  }

  // LMC 2208
  if (payload.delveDateRange && payload.delveDateRange.value) {
    let delveDateRange = [];
    delveDateRange.push({
      startDate: moment(payload.delveDateRange.value.startDate, AppConstants.DATE_FORMAT).format(AppConstants.DATE_FORMAT_API),
      endDate: moment(payload.delveDateRange.value.endDate, AppConstants.DATE_FORMAT).format(AppConstants.DATE_FORMAT_API),
    });
    payload.delveDateRange = delveDateRange;
  }

  if (isLoadMore && data) {
    payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
  }
  if (data.continuationToken && payload.pageNumber) {
    payload.requestContinuationToken = data.continuationToken;
  }
  return payload;
};

export const createDelveOrdersFilterPayload = (payload: any, data?: any, isLoadMore?: boolean, selectedChip?: any, isChipClicked?: boolean, tabValue?: string) => {
  payload.attributes = [];
  let selectedValue = [selectedChip] as any;
  if (selectedChip === AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.ALL_FILTER_CHIP) {
    selectedValue = [];
  } else if (selectedChip === AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP) {
    selectedValue = [false, selectedChip];
  } else if (selectedChip === AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.ISSUES_REPORTED_CHIP) {
    selectedValue = [AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.PARTIALLY_VERIFIED_ISSUES_CHIP, AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.VERIFIED_ISSUES_CHIP];
  }
  if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
    if (payload.returns) {
      let fieldName = payload.returns.fieldName;
      payload.attributes.push({ key: fieldName, value: selectedValue });
      delete payload.returns;
      delete payload.verified;
    }
  } else {
    if (payload.verified) {
      let fieldName = payload.verified.fieldName;
      payload.attributes.push({ key: payload.verified.fieldName, value: selectedValue });
      delete payload.verified;
      delete payload.returns;
    }
  }

  if (payload.hub) {
    if (payload.hub.value) {
      let hubValue = payload.hub.value.map((item: any) => item.value);
      let hubNameValue = [];
      if (payload.hubName && payload.hubName.value.length) {
        hubNameValue = payload.hubName.value.map((item: any) => item.value);
      }
      hubValue = hubValue.concat(hubNameValue);
      const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        hubValue = [];
      }
      if (selectNoneIdx > -1) {
        hubValue = [];
      }
      payload.attributes.push({
        key: payload.hub.fieldName,
        value: getUniqueValues(hubValue),
      });
      delete payload.hub;
      delete payload.hubName;
    } else {
      payload.attributes.push({
        key: payload.hub.fieldName,
        value: [],
      });
      delete payload.hub;
      delete payload.hubName;
    }
  }
  if (payload.deliveryArea) {
    let attrValue = [];
    if (payload.deliveryArea.value) {
      attrValue = getFilterAttributeValue(payload.deliveryArea.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryArea.fieldName,
      value: attrValue,
    });
    delete payload.deliveryArea;
  }
  if (payload.orderType) {
    let attrValue = [];
    if (payload.orderType.value) {
      attrValue = getFilterAttributeValue(payload.orderType.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.orderType.fieldName,
      value: attrValue,
    });
    delete payload.orderType;
  }
  if (payload.consignmentCode) {
    payload.attributes.push({
      key: payload.consignmentCode.key,
      value: payload.consignmentCode.value,
    });
    delete payload.consignmentCode;
  }
  // if (payload.deliveryStatus) {
  //   let attrValue = [];
  //   if (payload.deliveryStatus.value) {
  //     if (payload.deliveryStatus.value.length) {
  //       attrValue = getFilterAttributeValue(payload.deliveryStatus.value);
  //     } else {
  //       attrValue = ["READY_TO_DISPATCH", "PACKING_COMPLETE", "PREPARED", "PREPARING", "ON_HOLD", "PENDING_DELIVERY", "CANCELLED", "DELIVERED", "DELIVERY_FAILED", "PARTIALLY_DELIVERED"];
  //     }
  //   } else {
  //     attrValue = [];
  //   }
  //   payload.attributes.push({
  //     key: payload.deliveryStatus.fieldName,
  //     value: attrValue,
  //   });
  //   delete payload.deliveryStatus;
  // }
  if (payload.paymentType) {
    let attrValue = [];
    if (payload.paymentType.value) {
      attrValue = getFilterAttributeValue(payload.paymentType.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.paymentType.fieldName,
      value: attrValue,
    });
    delete payload.paymentType;
  }
  if (payload.carrier) {
    let attrValue = [];
    if (payload.carrier.value) {
      attrValue = getFilterAttributeValue(payload.carrier.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.carrier.fieldName,
      value: attrValue,
    });
    delete payload.carrier;
  }
  if (payload.deliveryProposition) {
    payload.attributes.push({
      key: payload.deliveryProposition.fieldName,
      value: ["STD"],
    });
    delete payload.deliveryProposition;
  }
  if (payload.routeId) {
    let attrValue = [];
    if (payload.routeId.value) {
      attrValue = getFilterAttributeValue(payload.routeId.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.routeId.fieldName,
      value: attrValue,
    });
    delete payload.routeId;
  }
  if (payload.routeStatus) {
    let attrValue = [];
    if (payload.routeStatus.value) {
      attrValue = getFilterAttributeValue(payload.routeStatus.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.routeStatus.fieldName,
      value: attrValue,
    });
    delete payload.routeStatus;
  }
  if (payload.driver) {
    let attrValue = [];
    if (payload.driver.value) {
      attrValue = getFilterAttributeValue(payload.driver.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.driver.fieldName,
      value: attrValue,
    });
    delete payload.driver;
  }
  if (payload.vehicle) {
    let attrValue = [];
    if (payload.vehicle.value) {
      attrValue = getFilterAttributeValue(payload.vehicle.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.vehicle.fieldName,
      value: attrValue,
    });
    delete payload.vehicle;
  }
  if (payload.helper) {
    let attrValue = [];
    if (payload.helper.value) {
      attrValue = getFilterAttributeValue(payload.helper.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.helper.fieldName,
      value: attrValue,
    });
    delete payload.helper;
  }
  if (payload.deliveryFailedReason) {
    let attrValue = [];
    if (payload.deliveryFailedReason.value) {
      attrValue = getFilterAttributeValue(payload.deliveryFailedReason.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryFailedReason.fieldName,
      value: attrValue,
    });
    delete payload.deliveryFailedReason;
  }
  if (payload.createdBy) {
    let attrValue = [];
    if (payload.createdBy.value) {
      attrValue = getFilterAttributeValue(payload.createdBy.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.createdBy.fieldName,
      value: attrValue,
    });
    delete payload.createdBy;
  }
  if (payload.deliverySlot && payload.deliverySlot.value) {
    let delSlotValue = payload.deliverySlot.value.map((item: any) => item.value);
    let dateRange = [];
    const selectAllIdx = delSlotValue.indexOf(AppConstants.SELECT_ALL.value);
    if (payload.dateRange && payload.dateRange.value) {
      const allDates = enumerateDaysBetweenDates(payload.dateRange.value.startDate, payload.dateRange.value.endDate);
      if (selectAllIdx > -1 || !delSlotValue.length) {
        payload.isDateRange = true;
        dateRange.push({
          startTime: moment(payload.dateRange.value.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
          endTime: moment(payload.dateRange.value.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND),
        });
      } else {
        payload.isDateRange = false;
        delSlotValue.forEach((slot: any) => {
          const splitSlot = slot.split("-");
          const startSlot = splitSlot[0].split(":");
          const endSlot = splitSlot[1].split(":");
          if (allDates.length) {
            allDates.forEach((date: string) => {
              dateRange.push({
                startTime: moment(date, AppConstants.DATE_FORMAT).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                endTime: moment(date, AppConstants.DATE_FORMAT).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
              });
            });
          }
        });
      }
      if (payload.reset) {
        delete payload.isDateRange;
      }
    }
    payload.dateRange = dateRange;
    delete payload.displayRouteStatus;
    delete payload.deliverySlot;
    delete payload.reset;
  }

  // LMC 2208
  if (payload.delveDateRange && payload.delveDateRange.value) {
    let delveDateRange = [];
    delveDateRange.push({
      startDate: moment(payload.delveDateRange.value.startDate, AppConstants.DATE_FORMAT).format(AppConstants.DATE_FORMAT_API),
      endDate: moment(payload.delveDateRange.value.endDate, AppConstants.DATE_FORMAT).format(AppConstants.DATE_FORMAT_API),
    });
    payload.delveDateRange = delveDateRange;
  }

  if (isLoadMore && data) {
    payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
  }
  if (data.continuationToken && payload.pageNumber) {
    payload.requestContinuationToken = data.continuationToken;
  }
  return payload;
};

export const createPayloadForExcelExport = (payload: any, data?: any, isLoadMore?: boolean, selectedChip?: any, tabValue?: string) => {
  let newPayload = createDelveOrdersFilterPayload(payload, data, isLoadMore, selectedChip, false, tabValue);
  if (!isUndefined(newPayload)) {
    if (!isUndefined(newPayload.isDelve)) {
      if (newPayload.isDelve) {
        const { delveDateRange } = newPayload;
        newPayload.readyToDispatchDateRange = delveDateRange;
        newPayload.dateRange = [];
        delete newPayload.delveDateRange;
      } else {
        newPayload.isDelve = true;
        delete newPayload.delveDateRange;
        newPayload.readyToDispatchDateRange = [];
      }
    }
  }
  var orderFilterRequestDto = { orderFilterRequestDto: newPayload };
  return orderFilterRequestDto;
};

export const createCratesInfoPayload = (payload: any) => {
  if (payload) {
    let consignmentCode;
    let storeCode;
    if (payload.consignmentCode) {
      consignmentCode = payload.consignmentCode;
    }
    if (payload.posInformation) {
      storeCode = payload.posInformation.store;
    }
    var consignmentCodePosCodeMap = {
      consignmentCodePosCodeMap: {
        [consignmentCode]: storeCode,
      },
    };
    return consignmentCodePosCodeMap;
  }
};

export const getDropdownsData = (payload: any, codeKey?: string, valueKey?: string) => {
  return payload.length
    ? payload.map((item: any) => {
        return {
          code: codeKey ? item[codeKey] : item.id,
          value: valueKey ? item[valueKey] : item.name,
        };
      })
    : [];
};

export const getTotalCrates = (consignments: any[]) => {
  let totalCrates = 0;
  if (consignments.length) {
    consignments.forEach((item: any) => {
      if (item.data && item.data.deliveryInformation) {
        totalCrates += parseInt(item.data.deliveryInformation.totalCratesCount);
      }
    });
  }
  return totalCrates;
};

export const getAddressInfo = (addressObj: Address) => {
  return addressObj
    ? `${addressObj.building ? addressObj.building + ", " : ""}${addressObj.apartment ? addressObj.apartment + ", " : ""}${addressObj.zoneNumber ? addressObj.zoneNumber + ", " : ""}${
        addressObj.makani ? addressObj.makani + ", " : ""
      }${addressObj.streetNumber ? addressObj.streetNumber + ", " : ""}${addressObj.streetName ? addressObj.streetName + ", " : ""}${addressObj.area ? addressObj.area + ", " : ""}${
        addressObj.landmark ? addressObj.landmark + ", " : ""
      }${addressObj.zoneNumber ? addressObj.zoneNumber : ""}
  ${addressObj.city ? addressObj.city + ", " : ""}${addressObj.postalCode ? addressObj.postalCode + ", " : ""}${addressObj.countryIsoCode ? addressObj.countryIsoCode : ""}`
    : "";
};

export const getLatLong = (addressObj: Address) => {
  return addressObj ? `${addressObj.latitude ? parseFloat(addressObj.latitude).toFixed(5) + ", " : ""}${addressObj.longitude ? parseFloat(addressObj.longitude).toFixed(5) : ""}` : "";
};

export const getSortedProducts = (items: any, tabValue: any, unOrderedProductsList?: any) => {
  let sortedProducts: any = [];
  let issueProducts: any = [];
  let products: any = [];
  if (items !== undefined && Array.isArray(items) && items.length > 0) {
    items.forEach((item: any) => {
      if (item !== undefined) {
        if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
          if (item.qcReportsReturn !== undefined && item.qcReportsReturn.reasonDescription !== undefined && item.qcReportsReturn.reasonDescription !== undefined) {
            issueProducts.push(item);
          } else {
            products.push(item);
          }
        } else {
          if (item && item.associatedCrates && item.associatedCrates[0] && item.associatedCrates[0].qcReports && item.associatedCrates[0].qcReports.reasonDescription) {
            issueProducts.push(item);
          } else {
            products.push(item);
          }
        }
      }
    });
  }
  sortedProducts = issueProducts.concat(products);
  if (!isUndefined(unOrderedProductsList)) {
    if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value && !isUndefined(unOrderedProductsList.returnedProducts) && isArrayNonEmpty(unOrderedProductsList.returnedProducts)) {
      sortedProducts = sortedProducts.concat(unOrderedProductsList.returnedProducts);
    } else if (tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value && !isUndefined(unOrderedProductsList.deliveryProducts) && isArrayNonEmpty(unOrderedProductsList.deliveryProducts)) {
      sortedProducts = sortedProducts.concat(unOrderedProductsList.deliveryProducts);
    }
  }
  return sortedProducts;
};

export const getVerifiedVerificationStatusField = (fieldName: string, statusCode: string) => {
  let statusCodeCopy = "" as any;
  if (!isUndefined(statusCode) && isAString(statusCode)) {
    statusCodeCopy = getStatusWithUnderScore(statusCode);
    const statuses: any = AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.VERIFICATION_STATUS;
    const newStatus = statusCodeCopy.toUpperCase();
    return statusCode && newStatus && fieldName && statuses[newStatus] && statuses[newStatus][fieldName] ? statuses[newStatus][fieldName] : "";
  }
  return "";
};

const getReportedQtyValueFromItem = (item: Product, tabValue: string) => {
  const reportedQty = Number(getValueFromMetaData(item, tabValue, "reportedQty"));
  if (Number(reportedQty) > 0 && item && item.uom && isAString(item.uom) && isUomGrams(item.uom)) {
    return (Number(item.shippedQty) / 1000) * Number(reportedQty);
  }
  return reportedQty || 0;
};

export const getCratesFromProducts = (items: Product, tabValue: string, unOrderedProductsList?: any) => {
  let crates_details_object: any = {};
  let crates_data_obj: any = {};
  let issues_reported_cost = 0;
  let item_issues_object: any = {};
  if (items !== undefined && Array.isArray(items) && items.length > 0) {
    items.forEach((item: Product) => {
      if (!isUndefined(item)) {
        if (item.substitutedProducts !== undefined && Array.isArray(item.substitutedProducts) && item.substitutedProducts.length > 0) {
          item.substitutedProducts.forEach((product: SubsProducts) => {
            if (tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value && product.associatedCrates !== undefined && Array.isArray(product.associatedCrates) && product.associatedCrates.length > 0) {
              const data_obj = generateCratesObj(product.associatedCrates, crates_data_obj, item_issues_object, product, item);
              if (data_obj !== undefined && typeof data_obj === "object" && Object.keys(data_obj).length > 0) {
                crates_data_obj = data_obj.crates_data_obj;
                item_issues_object = data_obj.item_issues_object;
                issues_reported_cost = issues_reported_cost + data_obj.issues_reported_cost;
              }
            } else {
              const reportedQty = Number(getValueFromMetaData(item, tabValue, "reportedQty"));
              if (Number(reportedQty) > 0) {
                const unitPrice = Number(product?.effectiveUnitPrice && Number(product?.effectiveUnitPrice) > 0 ? product?.effectiveUnitPrice : product?.unitPrice || "0");
                const reported_cost = getReportedQtyValueFromItem(item, tabValue) * unitPrice;
                issues_reported_cost = Number(issues_reported_cost) + reported_cost;
                const reasonCode = getReportValueFromMetaData(item, tabValue, "reasonCode");
                if (reasonCode !== undefined && reasonCode !== "") {
                  if (item_issues_object[reasonCode] !== undefined) {
                    item_issues_object[reasonCode].reasonDescription = getReportValueFromMetaData(item, tabValue, "reasonDescription");
                    item_issues_object[reasonCode].issues_cost = Number(item_issues_object[reasonCode].issues_cost) + reported_cost;
                  } else {
                    item_issues_object[reasonCode] = {
                      reasonDescription: getReportValueFromMetaData(item, tabValue, "reasonDescription"),
                      issues_cost: reported_cost,
                    };
                  }
                }
              }
            }
          });
        } else if (tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value && item.associatedCrates !== undefined && Array.isArray(item.associatedCrates) && item.associatedCrates.length > 0) {
          const data_obj = generateCratesObj(item.associatedCrates, crates_data_obj, item_issues_object, item);
          if (data_obj !== undefined && typeof data_obj === "object" && Object.keys(data_obj).length > 0) {
            crates_data_obj = data_obj.crates_data_obj;
            item_issues_object = data_obj.item_issues_object;
            issues_reported_cost = issues_reported_cost + data_obj.issues_reported_cost;
          }
        } else {
          if (Number(getValueFromMetaData(item, tabValue, "reportedQty")) > 0) {
            const unitPrice = Number(item?.effectiveUnitPrice && Number(item?.effectiveUnitPrice) > 0 ? item?.effectiveUnitPrice : item?.unitPrice);
            const reported_cost = getReportedQtyValueFromItem(item, tabValue) * unitPrice;
            issues_reported_cost = Number(issues_reported_cost) + reported_cost;
            const reasonCode = getReportValueFromMetaData(item, tabValue, "reasonCode");
            if (reasonCode !== undefined && reasonCode !== "") {
              if (item_issues_object[reasonCode] !== undefined) {
                item_issues_object[reasonCode].reasonDescription = getReportValueFromMetaData(item, tabValue, "reasonDescription");
                item_issues_object[reasonCode].issues_cost = Number(item_issues_object[reasonCode].issues_cost) + reported_cost;
              } else {
                item_issues_object[reasonCode] = {
                  reasonDescription: getReportValueFromMetaData(item, tabValue, "reasonDescription"),
                  issues_cost: reported_cost,
                };
              }
            }
          }
        }
      }
    });
  }
  if (!isUndefined(unOrderedProductsList)) {
    let items = [] as any;
    if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value && !isUndefined(unOrderedProductsList.returnedProducts) && isArrayNonEmpty(unOrderedProductsList.returnedProducts)) {
      items = unOrderedProductsList.returnedProducts;
    } else if (tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value && !isUndefined(unOrderedProductsList.deliveryProducts) && isArrayNonEmpty(unOrderedProductsList.deliveryProducts)) {
      items = unOrderedProductsList.deliveryProducts;
    }

    if (items && isArrayNonEmpty(items)) {
      items.forEach((item: any) => {
        if (!isUndefined(item) && !isUndefined(item.reportedQty) && Number(item.reportedQty) > 0) {
          const unitPrice = Number(item?.effectiveUnitPrice && Number(item?.effectiveUnitPrice) > 0 ? item?.effectiveUnitPrice : item?.unitPrice);
          const reported_cost = Number(item.reportedQty) * unitPrice; // getReportedCostBasedOnQtyValue(item) * unitPrice;
          issues_reported_cost = Number(issues_reported_cost) + reported_cost;
          const reasonCode = item.reportedReasonCode;
          if (reasonCode !== undefined && reasonCode !== "") {
            if (item_issues_object[reasonCode] !== undefined) {
              item_issues_object[reasonCode].reasonDescription = item.reportedReasonDescription;
              item_issues_object[reasonCode].issues_cost = Number(item_issues_object[reasonCode].issues_cost) + reported_cost;
            } else {
              item_issues_object[reasonCode] = {
                reasonDescription: item.reportedReasonDescription,
                issues_cost: reported_cost,
              };
            }
          }
          if (tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value && !isUndefined(item) && !isUndefined(item.crateId)) {
            if (!isUndefined(crates_data_obj) && crates_data_obj[item.crateId]) {
              crates_data_obj[item.crateId].reportedQty = crates_data_obj[item.crateId].reportedQty + Number(item.reportedQty);
              crates_data_obj[item.crateId].issueCost = crates_data_obj[item.crateId].issueCost + reported_cost;
              crates_data_obj[item.crateId].issue_found = true;
              if (crates_data_obj[item.crateId].productDetails[item.reportedReasonCode] !== undefined) {
                crates_data_obj[item.crateId].productDetails[item.reportedReasonCode].push({ ...item, reported_cost: reported_cost });
              } else {
                crates_data_obj[item.crateId].productDetails[item.reportedReasonCode] = [{ ...item, reported_cost: reported_cost }];
              }
            }
          }
        }
      });
    }
  }
  crates_details_object.crates_data_obj = crates_data_obj;
  crates_details_object.issues_reported_cost = issues_reported_cost;
  crates_details_object.item_issues_object = item_issues_object;
  return crates_details_object;
};

const getReportedQtyValueFromCrate = (item: any, reportedQty: number): number => {
  if (Number(reportedQty) > 0 && item && item.uom && isAString(item.uom) && isUomGrams(item.uom)) {
    return (Number(item.shippedQty) / 1000) * Number(reportedQty);
  }
  return reportedQty || 0;
};

export const generateCratesObj = (cratesList: any, _original_crates_data_obj: any, _original_item_issues_object: any, item: any, mainItem?: any) => {
  let crates_data_obj = JSON.parse(JSON.stringify(_original_crates_data_obj)) as any;
  let item_issues_object = JSON.parse(JSON.stringify(_original_item_issues_object)) as any;
  let issues_reported_cost = 0;

  if (cratesList !== undefined && Array.isArray(cratesList) && cratesList.length > 0) {
    cratesList.forEach((crate: any) => {
      if (crate !== undefined && crate.crateId !== undefined) {
        let issue_found = false;
        if (crates_data_obj[crate.crateId] !== undefined) {
          const { productQty, verifiedQty, reportedQty } = crates_data_obj[crate.crateId];
          crates_data_obj[crate.crateId].verified =
            crate?.qcStatus && !isUndefined(crate?.qcStatus?.verified) && crate?.qcStatus?.verified === true ? ++crates_data_obj[crate.crateId].verified : crates_data_obj[crate.crateId].verified; // TODO
          crates_data_obj[crate.crateId].productQty = crate?.quantity ? parseInt(productQty) + parseInt(crate.quantity) : productQty;
          crates_data_obj[crate.crateId].verifiedQty = crate?.qcStatus?.verifiedQty ? parseInt(verifiedQty) + parseInt(crate?.qcStatus?.verifiedQty) : verifiedQty;
          crates_data_obj[crate.crateId].reportedQty = crate?.qcStatus?.reportedQty ? parseInt(reportedQty) + parseInt(crate?.qcStatus?.reportedQty) : reportedQty;
        } else {
          crates_data_obj[crate.crateId] = {
            verified: crate?.qcStatus && !isUndefined(crate?.qcStatus?.verified) && crate?.qcStatus?.verified === true ? 1 : 0,
            productQty: crate?.quantity ? parseInt(crate.quantity) : 0,
            verifiedQty: crate?.qcStatus?.verifiedQty ? parseInt(crate?.qcStatus?.verifiedQty) : 0,
            reportedQty: crate?.qcStatus?.reportedQty ? parseInt(crate?.qcStatus?.reportedQty) : 0,
            issueCost: 0,
            productDetails: {},
            issue_found: false,
          };
        }

        if (item !== undefined && item.sku !== undefined) {
          let product_details_object: any = {
            name: item.name,
            quantity: item.quantity || 0,
            shippedQty: item.shippedQty,
            unitPrice: item?.effectiveUnitPrice && Number(item?.effectiveUnitPrice) > 0 ? item?.effectiveUnitPrice : item.unitPrice,
            imgUrl: item.imgUrl,
            qcStatusDelivery: { ...item.qcStatusDelivery },
            qcReports: {},
            reported_cost: 0,
          };

          if (mainItem !== undefined && mainItem.quantity !== undefined && Number(mainItem.quantity) > 0) {
            product_details_object.quantity = mainItem.quantity;
          }
          if (crate !== undefined && crate.qcStatus !== undefined && crate.qcStatus.reportedQty !== undefined && parseInt(crate.qcStatus.reportedQty) > 0) {
            issue_found = true;
            const unitPrice: number = Number(item?.effectiveUnitPrice && Number(item?.effectiveUnitPrice) > 0 ? item?.effectiveUnitPrice : item.unitPrice);
            const reported_cost: number = getReportedQtyValueFromCrate(item, Number(crate.qcStatus.reportedQty)) * unitPrice;
            product_details_object.reported_cost = reported_cost;
            crates_data_obj[crate.crateId].issueCost = Number(crates_data_obj[crate.crateId].issueCost) + reported_cost;
            issues_reported_cost = Number(issues_reported_cost) + reported_cost;

            if (crate.qcReports !== undefined && crate.qcReports.reasonCode !== undefined) {
              product_details_object.qcReports = crate.qcReports;
              if (item_issues_object[crate.qcReports.reasonCode] !== undefined) {
                item_issues_object[crate.qcReports.reasonCode].reasonDescription = crate.qcReports.reasonDescription;
                item_issues_object[crate.qcReports.reasonCode].issues_cost = Number(item_issues_object[crate.qcReports.reasonCode].issues_cost) + reported_cost;
              } else {
                item_issues_object[crate.qcReports.reasonCode] = {
                  reasonDescription: crate.qcReports.reasonDescription,
                  issues_cost: reported_cost,
                };
              }

              if (crates_data_obj[crate.crateId].productDetails[crate.qcReports.reasonCode] !== undefined) {
                crates_data_obj[crate.crateId].productDetails[crate.qcReports.reasonCode].push(product_details_object);
              } else {
                crates_data_obj[crate.crateId].productDetails[crate.qcReports.reasonCode] = [product_details_object];
              }
            }
          }
        }

        if (crates_data_obj[crate.crateId].issue_found !== undefined && !crates_data_obj[crate.crateId].issue_found) {
          crates_data_obj[crate.crateId].issue_found = issue_found;
        }
      }
    });
  }
  return { crates_data_obj, item_issues_object, issues_reported_cost };
};

export const getItemNameForDelveOrderList = (item: any) => {
  if (
    item.substitutedProducts !== undefined &&
    Array.isArray(item.substitutedProducts) &&
    item.substitutedProducts.length > 0 &&
    item.substitutedProducts[0] !== undefined &&
    item.substitutedProducts[0].name !== undefined &&
    item.substitutedProducts[0].name.en !== undefined
  ) {
    return item.substitutedProducts[0].name.en;
  } else if (item.name !== undefined && item.name.en !== undefined) {
    return item.name.en;
  }
  return "";
};

export const getItemShippedQtyForDelveOrderList = (item: any, tabValue: any) => {
  if (item.substitutedProducts !== undefined && Array.isArray(item.substitutedProducts) && item.substitutedProducts.length > 0 && item.substitutedProducts[0] !== undefined) {
    if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
      if (item.substitutedProducts[0].rejectedQty !== undefined) {
        return item.substitutedProducts[0].rejectedQty;
      }
    } else if (item.substitutedProducts[0].shippedQty !== undefined) {
      return item.substitutedProducts[0].shippedQty;
    }
  } else if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
    if (item.rejectedQty !== undefined) {
      return item.rejectedQty;
    }
  } else if (item.shippedQty !== undefined) {
    return item.shippedQty;
  }
  return "0";
};

export const getItemReportedQtyForDelveOrderList = (item: any, tabValue: any) => {
  if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
    if (item.qcStatusReturn !== undefined && item.qcStatusReturn.reportedQty !== undefined && Number(item.qcStatusReturn.reportedQty) > 0) {
      return Number(item.qcStatusReturn.reportedQty);
    } else if (item.reportedQty && Number(item.reportedQty) > 0) {
      return item.reportedQty;
    }
  } else {
    if (
      !isUndefined(item.associatedCrates) &&
      !isUndefined(item.associatedCrates[0]) &&
      !isUndefined(item.associatedCrates[0].qcStatus) &&
      !isUndefined(item.associatedCrates[0].qcStatus.reportedQty) &&
      Number(item.associatedCrates[0].qcStatus.reportedQty) > 0
    ) {
      return item.associatedCrates[0].qcStatus.reportedQty;
    } else if (!isUndefined(item) && !isUndefined(item.qcStatusDelivery) && !isUndefined(item.qcStatusDelivery.reportedQty) && Number(item.qcStatusDelivery.reportedQty) > 0) {
      return Number(item.qcStatusDelivery.reportedQty);
    } else if (item.reportedQty && Number(item.reportedQty) > 0) {
      return item.reportedQty;
    }
  }
  return "0";
};

export const getItemStatusForDelveOrderList = (item: any, tabValue: any) => {
  if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
    if (item.qcStatusReturn !== undefined && item.qcStatusReturn.verified !== undefined) {
      return item.qcStatusReturn.verified ? "Verified" : "Unverified";
    }
  } else {
    if (item.qcStatusDelivery && item.qcStatusDelivery.verified) {
      return item.qcStatusDelivery.verified ? "Verified" : "Unverified";
    }
  }
  return "";
};

export const getItemSQtyForDelveOrderList = (item: any, tabValue: any) => {
  if (item.substitutedProducts !== undefined && Array.isArray(item.substitutedProducts) && item.substitutedProducts.length > 0 && item.substitutedProducts[0] !== undefined) {
    if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value && item.substitutedProducts[0].shippedQty !== undefined) {
      return item.substitutedProducts[0].shippedQty;
    } else if (item.substitutedProducts[0].quantity !== undefined) {
      return item.substitutedProducts[0].quantity;
    } else if (item.quantity !== undefined) {
      return item.quantity;
    }
  } else if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
    if (item.shippedQty !== undefined) {
      return item.shippedQty;
    }
  } else if (item.quantity !== undefined) {
    return item.quantity;
  }
  return "0";
};


export const getUnOrderedProductsFromMetaData = (consignment: any, tabValue: string) => {
  if (!isUndefined(consignment) && !isUndefined(consignment.metadata)) {
    return getValueFromMetaData(consignment.metadata, tabValue, "unOrderedProducts");
  }
  return false;
};
