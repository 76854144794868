const AppConstants = {
  COUNTRY: "country",
  COUNTRY_CODE: "countryCode",
  COUNTRY_URL: "countryUrl",
  HUB_CODE: "hubCode",
  COUNTRY_ALL: "ALL",
  ROLE: "role",
  HUB_NAME: "hubName",
  DELIVERY_STATUS: "deliveryStatus",
  DELIVERY_DATE: "deliveryDate",
  CARREFOUR: "(carrefour|market)",
  DATE_FORMAT: "DD/MM/YYYY",
  DATE_FORMAT_UI: "DD MMM YY",
  DATE_FORMAT_BACKEND: "YYYY-MM-DDTHH:mm:ss",
  DATE_FORMAT_DAY_MONTH: "DD MMMM",
  SLOT_FORMAT_UI: "HH:mm",
  SLOT_FORMAT_BACKEND: "HH:mm:ss",
  DATE_FORMAT_API: "YYYY-MM-DD",
  TIMESTAMP: "DD/MM/YYYY HH:mm:ss",
  DATE_TIME_FORMAT: "DD/MM/YY HH:mm",
  CLEAR_ALL_FILTERS_THRESHOLD: 2,
  SEARCH_BOX_LIMIT: 3,
  SEARCH_TEXT_LIMIT: "Search box must have at least 3 characters",
  NO_OPTIONS_TEXT: "No options to select",
  COUNTRY_LB: "LB",
  COUNTRY_KENYA: "KE",
  COUNTRY_UAE: "AE",
  COUNTRY_OMAN: "OM",
  COUNTRY_OBJ: {
    value: "AE",
    name: "United Arab Emirates",
  },
  COUNTRY_MAP: {
    AE: "mafuae",
    BH: "mafbhr",
    EG: "mafegy",
    JO: "mafjor",
    KE: "mafken",
    KW: "mafkwt",
    LB: "maflbn",
    OM: "mafomn",
    PK: "mafpak",
    QA: "mafqat",
    SA: "mafsau",
    GE: "mafgeo",
  },
  SELECT_ALL: {
    name: "Select All",
    value: "all",
  },
  SELECT_NONE: {
    name: "Please Select",
    value: "n/a",
  },
  ITEMS: [
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "2",
    },
  ],
  HUB: {
    name: "Al Saqr, HyperMarket, Customer Service DeskPlease Select",
    value: "D04",
  },
  CATEGORY: {
    name: "Consignments",
    value: "Consignments",
  },
  ROUTES: {
    name: "Routes",
    value: "Routes",
  },
  CATEGORIES: {
    category: [
      {
        name: "Consignments",
        value: "Consignments",
      },
      {
        name: "Routes",
        value: "Routes",
      },
    ],
  },
  CATEGORIES_HUBWISE: {
    category: [
      {
        name: "Consignments",
        value: "Consignments",
      },
    ],
  },
  DELIVERY_TYPE: {
    name: "Food",
    value: "Food",
  },
  BUTTONS: {
    SEARCH: "SEARCH",
    RESET: "RESET",
    CLEAR_ALL: "CLEAR ALL",
    CLEAR: "CLEAR",
    CLEAR_ALL_FILTERS: "Clear All Filters",
    LOAD_MORE_LOADING: "LOADING MORE DATA",
    LOGIN: "LOGIN",
    FORGOT_PWD: "Forgot Password?",
    REGISTER_USER: "New User Registration",
    SUBMIT: "SUBMIT",
    CREATE_ROUTE: "CREATE ROUTE",
    CANCEL: "CANCEL",
    YES: "Yes",
    NO: "No",
    PROCEED: "PROCEED",
    ASSIGN: "Assign",
    CLEAR_NEW: "Clear",
    OPTIMIZE: "OPTIMIZE",
    DISCARD: "DISCARD CHANGES",
    SAVE: "SAVE",
    APPLY: "APPLY",
    ADD_USER: "ADD USER",
    ADD_HUB: "ADD HUB",
    APPROVE: "APPROVE",
    REJECT: "REJECT",
    RESET_PWD: "RESET PASSWORD",
    CONFIRM: "CONFIRM",
    GENERATE: "GENERATE ID",
    REFRESH_MAP: "REFRESH MAP",
    CONTINUE: "CONTINUE",
    END_ACTIVE_SESSION: "END ACTIVE SESSION",
    END_DRIVER_HELPER_SESSION: "RELEASE DRIVER/HELPER",
    GET_CRATE_INFO: "GET CRATE INFORMATION",
    EDIT_ITEMS: "EDIT ITEMS",
    DOWNLOAD_QR: "Download QR Code",
    REGENERATE_QR: "Re generate  QR Code",
    VIEW_AND_UPLOAD: "View and Upload Cash reciepts",
    PUBLISH: "Publish",
    ADD: "Add",
    SHOW_ORDERS: "Show Orders",
    ADD_TO_EXISTING_ROUTES: "Add to Existing Routes",
    RELEASE_DRIVER: "Release Driver",
    BACK: "Back",
    SEND_COMM: "Send Communication",
    DELETE: "DELETE",
    GENERATE_OTP: "GENERATE OTP",
    RESEND_OTP: "RESEND OTP",
    DOWNLOAD_ICON: "Download Icon",
  },
  SNACKBAR: {
    TYPES: {
      SUCCESS: "success",
      ERROR: "error",
    },
    AUTO_HIDE_TIMEOUT: 2500,
    AUTO_HIDE_TIMEOUT_PUBLISH: 5000,
  },
  RESPONSE_CONSTANTS: {
    ERROR_CODES: {
      DEFAULT_API_FAILED: "BR100",
    },
    DEFAULT_API_FAILED_ERROR_MSG: "Something went wrong, Please try again later",
  },
  VALIDATIONS: {
    EMAIL: {
      REQUIRED: {
        msg: "Email is required",
      },
      VALID_EMAIL: {
        msg: "Please enter a valid email",
      },
    },
    USERNAME: {
      REQUIRED: {
        msg: "Username is required",
      },
    },
    PASSWORD: {
      REQUIRED: {
        msg: "Password is required",
      },
      STRONG_PWD: {
        msg: "Password should be of minimum 8 characters length and should contain at least one Lowercase character, one Uppercase character, one Number and one Special character",
        value: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$",
      },
      ONE_OF: {
        msg: "Passwords should be same",
      },
    },
    SERVICE_TYPE: {
      REQUIRED: {
        msg: "Service type is required",
        value: "\\b(?![n\\/a]\\b)\\w+"
      },
    },
  },
  ADMIN_ROLE: "ROLE_SUPER_ADMIN",
  CPM_ROLE: "ROLE_COLLECTION_POINT_MANAGER",
  CHD_ROLE: "ROLE_CARREFOUR_HOME_DELIVERY_MANAGER",
  VT_MANAGER_ROLE: "ROLE_STANDARD_VALET_TROLLEY_MANAGER",
  QC_MANAGER_ROLE: "ROLE_LAST_MILE_QC_MANAGER",
  DISPATCHER_ROLE: "ROLE_LAST_MILE_DISPATCHER",
  EXPRESS_DISPATCHER_ROLE: "ROLE_LAST_MILE_DISPATCHER_EXPRESS",
  EXPRESS_VIEWER_ROLE: "ROLE_LAST_MILE_VIEWER_EXPRESS",
  COUNTRY_ADMIN: "COUNTRY_ADMIN",
  DRIVER_ROLE: "ROLE_LAST_MILE_DRIVER",
  HELPER_ROLE: "ROLE_LAST_MILE_HELPER",
  ACCESS_DENIED: "You do not have access to view this page.",
  DEFAULT_STATUS: "UNPLANNED",
  COMPLETED: "COMPLETED",
  DRIVER: "Driver",
  HELPER: "Helper",
  TODAY_DATA_TEXT: "Shows only today's data",
  PLANNED: "PLANNED",
  TIME_PICKER_FORMAT_UI: "hh:mm",
  POP_UP_MESSAGE_CONSTANT: {
    DOWNLOAD_USER_INFORMATION: "Download User Information",
    DOWNLOAD_USER_INFORMATION_CONFIRMATION: "Are you sure you want to download/export the user information?",
  },
};

export default AppConstants;
