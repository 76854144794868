import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import * as yup from "yup";

import { Button, Grid, Typography } from "@material-ui/core";

import TextBox from "../common/TextBox";
import Loader from "../common/Loader";
import AppConstants from "../constants";
import CustomSnackbar from "../common/CustomSnackbar";

import { useStyles } from "./Login.styles";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../config/redux/reducers";
import { newUserLogin, resetUserResetPwd, userLogin, userResetPwd } from "./redux/loginSlice";

export interface LoginProps {
  resetPwd?: boolean;
}

const loginValidationSchema = yup.object({
  username: yup.string().required(AppConstants.LOGIN_CONSTANTS.VALIDATIONS.USERNAME.REQUIRED.msg),
  password: yup
    .string()
    .matches(new RegExp(AppConstants.LOGIN_CONSTANTS.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.LOGIN_CONSTANTS.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.LOGIN_CONSTANTS.VALIDATIONS.PASSWORD.REQUIRED.msg),
});

const resetPwdValidationSchema = yup.object({
  username: yup.string().required(AppConstants.LOGIN_CONSTANTS.VALIDATIONS.USERNAME.REQUIRED.msg),
  oldPassword: yup.string().required(AppConstants.LOGIN_CONSTANTS.VALIDATIONS.OLDPASSWORD.REQUIRED.msg),
  newPassword: yup
    .string()
    .matches(new RegExp(AppConstants.LOGIN_CONSTANTS.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.LOGIN_CONSTANTS.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.LOGIN_CONSTANTS.VALIDATIONS.PASSWORD.REQUIRED.msg),
  confirmPassword: yup
    .string()
    .matches(new RegExp(AppConstants.LOGIN_CONSTANTS.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.LOGIN_CONSTANTS.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.LOGIN_CONSTANTS.VALIDATIONS.PASSWORD.REQUIRED.msg)
    .oneOf([yup.ref("newPassword")], AppConstants.LOGIN_CONSTANTS.VALIDATIONS.PASSWORD.ONE_OF.msg),
});

const loginOtpValidationSchema = yup.object({
  code: yup
    .string()
    .matches(new RegExp(AppConstants.LOGIN_CONSTANTS.VALIDATIONS.OTP.VALIDITY.value), AppConstants.LOGIN_CONSTANTS.VALIDATIONS.OTP.VALIDITY.msg)
    .required(AppConstants.LOGIN_CONSTANTS.VALIDATIONS.OTP.REQUIRED.msg),
});

const UserLogin = (props: LoginProps) => {
  const { resetPwd } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, isLoggedIn, resetPwdSuccess, resetPwdMsg, role, error, errorCode, showOtp } = useSelector((state: AppState) => state.userLogin);

  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");

  const loginFormik = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      generateOtp(values);
    },
  });

  const loginOtpFormik = useFormik({
    initialValues: { code: "" },
    validationSchema: loginOtpValidationSchema,
    onSubmit: (values) => {
      loginUser(values);
    },
  });

  const resetPwdFormik = useFormik({
    initialValues: { username: sessionStorage.getItem("username") || "", oldPassword: "", newPassword: "", confirmPassword: "" },
    validationSchema: resetPwdValidationSchema,
    onSubmit: (values) => {
      resetPassword(values);
    },
  });

  if (!resetPwd) {
    if (resetPwdSuccess) {
      dispatch(resetUserResetPwd());
    }
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  }

  const getInitialValues = () => {
    return { username: "", code: "" };
  };

  const reGenerateOtp = () => {
    loginOtpFormik.setValues(getInitialValues());
    loginOtpFormik.setErrors(getInitialValues());
    dispatch(newUserLogin({ userdata: loginFormik.values }));
  };

  const generateOtp = (payload: any) => {
    if (payload) {
      dispatch(newUserLogin({ userdata: payload }));
    }
  };

  const loginUser = (payload: any) => {
    if (payload?.code?.length === 6 && !isNaN(payload?.code?.length)) {
      const userRecord = {
        code: payload.code,
        username: loginFormik.values.username || sessionStorage.getItem("username"),
      };
      dispatch(userLogin({ userdata: userRecord }));
    }
  };

  const resetPassword = (payload: any) => {
    if (payload) {
      dispatch(userResetPwd({ userdata: payload }));
    }
  };

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  };

  const handleOpenSnackbarOnEnter = useCallback(
    (node: HTMLElement, isAppearing: boolean) => {
      if (snackbarType.current === AppConstants.SNACKBAR.TYPES.ERROR && !error) {
        setOpenSnackbar(false);
      }
    },
    [error]
  );

  const checkRedirection = (role: any) => {
    switch (role) {
      case AppConstants.ADMIN_ROLE:
        return AppConstants.ROUTES.PLANNING;
      case AppConstants.CPM_ROLE:
        return AppConstants.ROUTES.CLICKNCOLLECT;
      case AppConstants.CHD_ROLE:
        return AppConstants.ROUTES.CARREFOUR_HOME_DLVRY;
      case AppConstants.VT_MANAGER_ROLE:
        return AppConstants.ROUTES.VALETTROLLEY;
      default:
        return AppConstants.ROUTES.HOME;
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push(checkRedirection(role));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (resetPwdSuccess) {
      openSnackbarPopup(resetPwdMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
      resetPwdFormik.setValues({ username: sessionStorage.getItem("username") || "", oldPassword: "", newPassword: "", confirmPassword: "" })
      resetPwdFormik.setErrors({ username: "", oldPassword: "", newPassword: "", confirmPassword: "" })
      history.push(AppConstants.ROUTES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPwdSuccess]);

  useEffect(() => {
    if (error) {
      if (resetPwd) {
        resetPwdFormik.resetForm();
      } else if (showOtp) {
        loginOtpFormik.setValues(getInitialValues());
      } else {
        loginFormik.resetForm();
      }
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
      if (!resetPwd && errorCode === AppConstants.LOGIN_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.PWD_EXP) {
        history.push(AppConstants.ROUTES.RESET_PWD);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if(resetPwd){
      resetPwdFormik.setValues({ username: sessionStorage.getItem("username") || "", oldPassword: "", newPassword: "", confirmPassword: "" });
      resetPwdFormik.setErrors({ username: "", oldPassword: "", newPassword: "", confirmPassword: "" });
    }
  }, [resetPwd])

  return (
    <Grid className={classes.root} container>
      {loading && <Loader></Loader>}
      <Grid className={resetPwd ? "resetItem" : "mainItem"} item>
        <Grid container className={classes.loginContainer} direction="column">
          <Grid className="headerLoginItem" item>
            <Grid container className="logoContainer" direction="column">
              <Grid item>
                <Typography className="logoSpanStyle">{`Wings`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <form onSubmit={loading ? undefined : resetPwd ? resetPwdFormik.handleSubmit : showOtp ? loginOtpFormik.handleSubmit : loginFormik.handleSubmit}>
            <Grid className="loginItem" item>
              <Grid container className="formContainer" direction="column">
                <Grid className="formItem" item>
                  <Typography className="formTitle">{resetPwd ? "Reset Password" : "Login to your account"}</Typography>
                </Grid>
                <Grid className="formItem" item>
                  <TextBox
                    fullWidth
                    variant="outlined"
                    onChange={resetPwd ? resetPwdFormik.handleChange : loginFormik.handleChange}
                    value={resetPwd ? resetPwdFormik.values.username : loginFormik.values.username}
                    error={resetPwd ? resetPwdFormik.touched.username && Boolean(resetPwdFormik.errors.username) : loginFormik.touched.username && Boolean(loginFormik.errors.username)}
                    helperText={resetPwd ? resetPwdFormik.touched.username && resetPwdFormik.errors.username : loginFormik.touched.username && loginFormik.errors.username}
                    textBoxId="usernameTextbox"
                    name="username"
                    type="text"
                    placeholderText="Username"
                    disabled={showOtp}
                  ></TextBox>
                </Grid>
                {resetPwd && (
                  <Grid className="formItem" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={resetPwdFormik.handleChange}
                      value={resetPwdFormik.values.oldPassword}
                      error={resetPwdFormik.touched.oldPassword && Boolean(resetPwdFormik.errors.oldPassword)}
                      helperText={resetPwdFormik.touched.oldPassword && resetPwdFormik.errors.oldPassword}
                      textBoxId="oldPasswordTextbox"
                      name="oldPassword"
                      type="text"
                      placeholderText="Enter Old Password"
                    ></TextBox>
                  </Grid>
                )}
                <Grid className="formItem" item>
                  <TextBox
                    fullWidth
                    variant="outlined"
                    onChange={resetPwd ? resetPwdFormik.handleChange : loginFormik.handleChange}
                    value={resetPwd ? resetPwdFormik.values.newPassword : loginFormik.values.password}
                    error={resetPwd ? resetPwdFormik.touched.newPassword && Boolean(resetPwdFormik.errors.newPassword) : loginFormik.touched.password && Boolean(loginFormik.errors.password)}
                    helperText={resetPwd ? resetPwdFormik.touched.newPassword && resetPwdFormik.errors.newPassword : loginFormik.touched.password && loginFormik.errors.password}
                    textBoxId="passwordTextbox"
                    type="password"
                    name={resetPwd ? "newPassword" : "password"}
                    placeholderText={resetPwd ? "Enter New password " : "Password"}
                    disabled={showOtp}
                  ></TextBox>
                </Grid>
                {resetPwd && (
                  <Grid className="formItem" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={resetPwdFormik.handleChange}
                      value={resetPwdFormik.values.confirmPassword}
                      error={resetPwdFormik.touched.confirmPassword && Boolean(resetPwdFormik.errors.confirmPassword)}
                      helperText={resetPwdFormik.touched.confirmPassword && resetPwdFormik.errors.confirmPassword}
                      textBoxId="confirmPasswordTextbox"
                      type="password"
                      name="confirmPassword"
                      placeholderText="Re-Enter Password"
                    ></TextBox>
                  </Grid>
                )}
                {!resetPwd && showOtp && (
                  <Grid className="formItem" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={loginOtpFormik.handleChange}
                      value={loginOtpFormik.values.code}
                      error={loginOtpFormik.touched.code && Boolean(loginOtpFormik.errors.code)}
                      helperText={loginOtpFormik.touched.code && loginOtpFormik.errors.code}
                      textBoxId="code"
                      name="code"
                      type="text"
                      placeholderText="Enter OTP"
                    ></TextBox>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid className="loginItem" item>
              <Grid container className="buttonsContainer" direction="column">
                {!resetPwd && !showOtp && (
                  <Grid className="btnItem forgotPwdItem" item>
                    <Link to="/reset-pwd" className="forgotPwdBtn">
                      {AppConstants.BUTTONS.RESET_PWD}
                    </Link>
                  </Grid>
                )}
                <Grid className="btnItem" item>
                  {showOtp && (
                    <Button variant="contained" className="loginBtn secondary" type="button" onClick={() => reGenerateOtp()}>
                      {AppConstants.BUTTONS.RESEND_OTP}
                    </Button>
                  )}
                  <Button variant="contained" className="loginBtn primary" type="submit">
                    {resetPwd ? AppConstants.BUTTONS.SUBMIT : showOtp ? AppConstants.BUTTONS.PROCEED : AppConstants.BUTTONS.LOGIN}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        onEnter={handleOpenSnackbarOnEnter}
        autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT}
        message={snackbarMessage.current}
        type={snackbarType.current}
      />
    </Grid>
  );
};

export default UserLogin;