import React from "react";

import { Card, Grid, Typography } from "@material-ui/core";
import { isNonEmptyArray, isNonEmptyObject, isNull, isObject, isUndefined } from "../../../utils/helpers.utils";
import { CRATE_PRODUCT_DETAILS_OBJECT_INTERFACE } from "../../../constants/DelveOrderListConstant";

interface DelveOrderDetailsItemReportedDetailsContentInterface {
  classes: any;
  crateId: any;
  metadata: any;
  crates_details_object: any;
}

const DelveOrderDetailsItemReportedDetailsContent = (props: DelveOrderDetailsItemReportedDetailsContentInterface) => {
  const { classes, crateId, metadata, crates_details_object } = props;

  const isProductDetailsPresent = () => {
    if (
      !isUndefined(crateId) &&
      !isNull(crateId) &&
      !isUndefined(crates_details_object) &&
      !isUndefined(crates_details_object.crates_data_obj) &&
      isObject(crates_details_object.crates_data_obj) &&
      isNonEmptyObject(crates_details_object.crates_data_obj) &&
      !isUndefined(crates_details_object.crates_data_obj[crateId]) &&
      !isUndefined(crates_details_object.crates_data_obj[crateId].productDetails) &&
      isObject(crates_details_object.crates_data_obj[crateId].productDetails) &&
      isNonEmptyObject(crates_details_object.crates_data_obj[crateId].productDetails)
    ) {
      return true;
    }
    return false;
  };

  const getReasonDescription = (item: CRATE_PRODUCT_DETAILS_OBJECT_INTERFACE) => {
    if (!isUndefined(item) && !isUndefined(item?.qcReports) && !isUndefined(item?.qcReports?.reasonDescription) && !isNull(item?.qcReports?.reasonDescription)) {
      return item?.qcReports?.reasonDescription;
    } else if (!isUndefined(item) && !isUndefined(item.reportedReasonDescription) && !isNull(item.reportedReasonDescription)) {
      return item.reportedReasonDescription;
    }
    return "Issue Found";
  };

  return (
    <Card className={classes.issueDetailsMainCard}>
      {isProductDetailsPresent() ? (
        Object.keys(crates_details_object.crates_data_obj[crateId].productDetails).map(
          (keyName: string, keyNameIndex: number) =>
            !isUndefined(keyName) &&
            !isUndefined(crates_details_object.crates_data_obj[crateId].productDetails[keyName]) &&
            isNonEmptyArray(crates_details_object.crates_data_obj[crateId].productDetails[keyName]) && (
              <Grid container spacing={1} className={classes.cratesOrderDetailsSummaryDiv}>
                <Grid item xs={12} className={classes.detailsItemContentDivStyle} key={`${keyNameIndex}-keyNameIndex-item_index-productDetails`}>
                  <Typography className={classes.cratesOrderDetailsIssueFoundspanStyle}>{`${getReasonDescription(
                    crates_details_object.crates_data_obj[crateId].productDetails[keyName][0]
                  )}`}</Typography>
                </Grid>
                {crates_details_object.crates_data_obj[crateId].productDetails[keyName].map((item: CRATE_PRODUCT_DETAILS_OBJECT_INTERFACE, item_index: number) => (
                  <Card className={classes.issueItemDetailsCard} key={`${keyNameIndex}-keyNameIndex-${item_index}-item_index-productDetails`}>
                    <Grid container spacing={0}>
                      <Grid item xs={2} className={classes.issueItemDetailsDiv}>
                        <img src={item.imgUrl} alt="Product" />
                      </Grid>
                      <Grid item xs={5} className={classes.issueItemDetailsDiv}>
                        <Typography className={classes.detailsItemContentValueStyle}>{`${(item && item.name && item.name.en) || "Data not available"}`}</Typography>
                        <Typography className={classes.detailsItemContentLabelStyle}>{`${item.unitPrice || 0} ${metadata.currency}`}</Typography>
                      </Grid>
                      <Grid item xs={3} className={classes.issueItemDetailsDiv}>
                        <Typography className={classes.detailsItemContentLabelStyle}>{`${"Reported Qty"}`}</Typography>
                        <Typography className={classes.detailsItemContentValueStyle}>{`${
                          item.qcStatusDelivery && item.qcStatusDelivery.reportedQty && Number(item.qcStatusDelivery.reportedQty) > 0
                            ? item.qcStatusDelivery.reportedQty
                            : item.reportedQty && Number(item.reportedQty) > 0
                            ? item.reportedQty
                            : 0
                        }`}</Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.issueItemDetailsDiv}>
                        <Typography className={classes.detailsItemContentLabelStyle}>{`${"Issue Cost"}`}</Typography>
                        <Typography className={classes.detailsItemContentValueStyle}>{`${item.reported_cost || 0} ${metadata.currency}`}</Typography>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Grid>
            )
        )
      ) : (
        <></>
      )}
    </Card>
  );
};

export default React.memo(DelveOrderDetailsItemReportedDetailsContent);
